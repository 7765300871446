import React, { useEffect, useState } from 'react';
import { Col, Form, Row, Select, Input } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import Text from '../../../components/Text';
import { oneColLayout } from '../../../utils';
import { fetchTaxOffices, fetchProvinces, fetchCompanies } from '../../../apiServices/commonApi';
import { setDistricts, setTaxOffices, setProvinces,setCompanies } from '../../../store/reducers/commonSlice';


const { Option } = Select;

function CompanyInfo() {
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const [showTaxOffice, setShowTaxOffice] = useState(false);
    const [loadingData, setLoadingData] = useState(false);
    const { user } = useSelector((state) => state.user);
    const { districts, provinces, taxOffices, companies } = useSelector((state) => state.common);
     
    useEffect(() => {      
        if (user && companies) {
            const _company =
            companies.length > 0 && companies.find((company) => company.name === user.companyType);
              
                form.setFields([
                {
                    name: 'companyType',
                    value: _company
                        ? { key: `bank-option-${_company.name}`, value: _company.name }
                        : form.getFieldValue(['companyType']),
                },
                { name: 'taxId', value: user.taxId },
                { name: 'title', value: user.title },
            ]);
        }
    }, [user, form,companies]);

    const onChangeProvince = async () => {
        const _province = provinces?.find((province) => province.provinceId === user?.province);
        if (_province) {
            dispatch(setDistricts(_province.districts));
            const response = await fetchTaxOffices(_province.provinceId);
            if (response) {
                dispatch(setTaxOffices(response));
            }
            form.setFields([
                { name: 'taxId', value: user.taxId },
                {
                    name: 'province',
                    value: _province
                        ? { key: `province-${_province.provinceId}`, value: _province.name }
                        : form.getFieldValue(['province']),
                },
            ]);
        }
    };

    useEffect(() => {
        if (user) {
            const _district =
                districts.length > 0 && districts.findIndex((district) => district === user.district);
            const _taxAdmin =
                taxOffices.length > 0 &&
                taxOffices.find((taxOffice) => taxOffice.name === user.taxAdministration);
                
            form.setFields([
                {
                    name: 'district',
                    value:
                        _district > 0
                            ? { key: `district${_district}`, value: districts[_district] }
                            : form.getFieldValue(['district']),
                },
                {
                    name: 'taxAdministration',
                    value: _taxAdmin
                        ? { key: `tax-Administration-${_taxAdmin.id}`, value: _taxAdmin.name }
                        : form.getFieldValue(['taxAdministration']),
                },
            ]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user, districts, taxOffices]);

    const getProvinces = async () => {
        setLoadingData(true);
        const response = await fetchProvinces();
        if (response) {
            dispatch(setProvinces(response));
            setLoadingData(false);
        }
    };

    const getCompanies = async () => {
        const response = await fetchCompanies();
        if (response) {
            dispatch(setCompanies(response));
        }  
    };


    useEffect(() => {
        getProvinces();    
        getCompanies();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (provinces?.length > 0) {
            onChangeProvince();
            setShowTaxOffice(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [provinces, user]);

    return (
        <Row>
            <Col {...oneColLayout}>
                <div className="mb" style={{marginBottom:'20px'}}>
                    <Text type="title" bold className='page-title'> 
                        Firma Bilgileri
                    </Text>
                    <Text>Firmanıza ait bilgileri görüntüleyin.</Text>
                </div>

                <Form form={form} name="accountSettings">
                    <Form.Item name="taxId">
                        <Input disabled placeholder="VKN/TCKN" />
                    </Form.Item>
                    <Form.Item name="companyType">
                        <Select disabled placeholder="Şirket Türü">
                            {companies.map((company) => (
                                <Option key={`bank-option-${company.name}`} value={company.name}>
                                    {company.name}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>

                    <Form.Item name="province">
                        <Select
                            disabled
                            placeholder="İl"
                            loading={loadingData}
                            onChange={onChangeProvince}
                            optionFilterProp="children"
                            showSearch>
                            {provinces.map((provnce) => (
                                <Option key={`province-${provnce.name}`} value={provnce.name}>
                                    {provnce.name}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                    {showTaxOffice && (
                        <Form.Item name="district">
                            <Select disabled placeholder="İlçe" optionFilterProp="children" showSearch>
                                {districts.map((district, id) => (
                                    <Option key={`district-${id}`} value={district}>
                                        {district}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                    )}
                    {showTaxOffice && (
                        <Form.Item name="taxAdministration">
                            <Select disabled placeholder="Vergi Dairesi" optionFilterProp="children" showSearch>
                                {taxOffices.map((taxOfc, id) => (
                                    <Option key={`tax-Administration-${id}`} value={taxOfc.name}>
                                        {taxOfc.name}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                    )}
                    <Form.Item name="title" >
                        <Input disabled placeholder="Ünvan" />
                    </Form.Item>
                </Form>
            </Col>
        </Row>
    );
}
export default CompanyInfo;
