/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    invoices: [],
    invoiceResId: 0,
    buyerData : [],
    buyerIdentity :'',
    financialInstitution:[],
    financialList :[],
    risks :[],
    banks :[]
};

const supplierFinanceSlice = createSlice({
    name: 'supplierFinance',
    initialState,
    reducers: {
        setInvoices(state, action) {
            state.invoices = action.payload;
        },
        setFinancialInstitution(state, action) {
            state.financialInstitution = action.payload;
        },
        setFinancialList(state, action) {
            state.financialList = action.payload;
        },
        setInvoiceResId(state, action) {
            state.invoiceResId = action.payload;
        },
        setBuyersList(state, action){
            state.buyerData = action.payload
        },
        setBuyerIdentity(state, action){
            state.buyerIdentity = action.payload
        },
        setRisk(state, action){
            state.risks = action.payload
        },
        setBankList(state, action){
            state.banks = action.payload
        }
    },
});

export const { setInvoices, setInvoiceResId, setBuyersList, setBuyerIdentity, setFinancialInstitution, setFinancialList,setRisk,setBankList} = supplierFinanceSlice.actions;
export default supplierFinanceSlice.reducer;
