import React, { useState, useEffect  } from 'react';
import moment from 'moment';
import 'moment-timezone';
import { Col, Row ,DatePicker, Button, Input, Form, Spin, Modal} from 'antd';
import { CalendarOutlined, SearchOutlined } from '@ant-design/icons';
import InputMask from 'react-input-mask';

import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import AliciTedarikci from '../../../assests/alici_tedarikci.png';
import Finans from '../../../assests/finans.png';
import Tedarikciler from '../../../assests/tedarikciler.png';
import FaturaVadesi from '../../../assests/faturavadesi.png';
import Table from '../../../components/Table';
import Text from '../../../components/Text';
import { convertFloatDotSeperated } from '../../../utils';
import { fetchInvoices, uploadInvoices, calculateDiscountAmountApi} from '../../../apiServices/supplierFinanceApi';
import { setInvoices, setInvoiceResId,  setFinancialList,setRisk } from '../../../store/reducers/supplierFinanceSlice';
import InvoicesDiscountSummary from './InvoicesDiscountSummary';
import urls from '../../../routes/urls';
import { HelpBox } from './styles';
import { fetchUser,fetchUserRisks } from '../../../apiServices/userApi';
import { setUser } from '../../../store/reducers/userSlice';
import LocalStorageService from '../../../services/LocalStorageService';



const SupplierFinancingList = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [selectInvoice, setSelectInvoice] = useState({});
    const [selectbuyer, setSelectBuyer] = useState({});
    const [active, setActive] = useState(false);
    const [pageLoading, setPageLoading] = useState(false);
    const [boxcActive, setBoxActive] = useState(false);
    const [isModalEntry , setIsModalEntry] = useState(false);
    const [isModalOk , setIsModalOK] = useState(false);
    // const [iban, setIban] = useState('');
    const [ibanNo, setIbanNo] = useState('');
    const [invoiceDataArray,setInvoiceDataArray] = useState([]); 
    const [financialInstitutionsArray,setFinancialInstitutionsArray] = useState([]); 
    const [supplierAverageCreditDayArray,setSupplierAverageCreditDayArray] = useState(0)

    const { invoices,  financialList } = useSelector((state) => state.supplierFinance);
    const { user, isLoggedIn } = useSelector((state) => state.user);
    const [riskLimitData,setRiskLimitData] = useState([]);
    const [pSize, setPsize] = useState(10);
    const [pNumber, setpNumber] = useState(1);
    const [totalDataCount, setTotalDataCount] = useState(1);
    const [filteredInfo, setFilteredInfo] = useState(null);
    const [invoicesData,setInvoicesData] = useState([]);
    const [filters, setFilters] = useState({
        invoiceDateBegin: '',
        invoiceDateEnd: '',
        invoiceTermBegin: '',
        invoiceTermEnd: '',
        minInvoiceAmount:'',
        maxInvoiceAmount:'',
        invoiceNumber:'',
      });
   

    const [form] = Form.useForm();
    const [formIban] = Form.useForm();

    const getRisks = async () => {
      const response = await fetchUserRisks(LocalStorageService.getAuthToken());
      if (response) {
        dispatch(setRisk(response.supplierRiskAndLimits))
        setRiskLimitData(response.supplierRiskAndLimits)
      }
    };

    const getTotal = (data) => {
      let totalAmount = 0;
      data.length && data.map((val) => (totalAmount += val.invoiceTotal));
      return totalAmount;
    };

    const discountInvoices = [];

    const getTotalCal = async (data) => {

      data && data.map((item) => {
        discountInvoices.push({
          invoiceTerm: item.invoiceTerm,
          invoiceAmount: item.invoiceTotal
        })
        return true
      })
      setPageLoading(true);

      const ChequeAmount = await getTotal(data);
      const response = await calculateDiscountAmountApi({ discountInvoices });
      setBoxActive(false);
      setActive(false);

      if (response) {
        setBoxActive(true);
        setActive(true);
        setPageLoading(false);
        const nData = [];
        riskLimitData.map(async (item) => {
          const calculatedDiscountAmounts = response.calculatedDiscountAmounts.filter((itemInv) => itemInv.financialInstitutionId === item.financialInstitutionId);
          nData.push({
            financialName: item.financialInstitutionName,
            financialId: item.financialInstitutionId,
            total: ChequeAmount,
            price: calculatedDiscountAmounts[0]?.calculatedSumAmount,
            supplierAverageCreditDay: calculatedDiscountAmounts[0]?.averageMaturity,
            supplierLimit: item.supplierLimit,
            supplierRisk: item.supplierRisk,
            financialTaxId: item.financialInstitutionTaxId
          })
          return true
        });

        dispatch(setFinancialList(nData))
      }
    };

    const rowSelection = {
      onChange: (selectedRowKeys, selectedRows) => {
        selectedRows > 0 ? setActive(true) : setActive(false); setBoxActive(false);
        if (selectedRowKeys.length > 0) {
          const filterRows = selectedRows.reduce((newData, row) => {
            setSelectBuyer({ buyerTitle: row.buyerTitle, buyerTaxNumber: row.buyerTaxNumber });
            newData.push({
              id: row.id,
              invoiceDate: row.invoiceDate,
              fileName: row.fileName,
              invoiceNumber: row.invoiceNumber,
              invoiceTotal: row.invoiceTotal,
              currency: row.currency,
              invoiceTerm: row.invoiceTerm,
              isApproved: row.isApproved,
              createdDate: row.createdDate,
              hashCode: row.hashCode,
              invoiceType: row.invoiceType
            });
            return newData;
          }, []);
          setSelectInvoice(filterRows);
          getTotalCal(selectedRows);
        } else {
          setSelectInvoice({});
        }
      },
      getCheckboxProps: (record) => ({
        index: record.index,
      }),
    };

  const onFinishConfirmation = (values) => {
      if(values){
        setIbanNo(values);
        setIsModalOK(true)
      }
    };

    const ibanModalClose = () => {
      formIban.resetFields();
      setIsModalEntry(false)
    }

    const handleOk = async () => {
      setIsModalOK(false);
      setIsModalEntry(false);
      formIban.resetFields();

      try {
        setLoading(true);
        const response = await uploadInvoices(
            {
                supplierId: user.id,
                buyer : {
                    id: invoices[0].buyerId,
                    title :  selectbuyer.buyerTitle,
                    taxId : selectbuyer.buyerTaxNumber,
                },
                averageMaturity: supplierAverageCreditDayArray,
                invoices : invoiceDataArray,
                financialInstitutions: [financialInstitutionsArray],
                iban:(ibanNo.iban).replace(/TR/g, '').replace(/\s+/g, ''),
            },
            isLoggedIn
        );
       
        if (response) {
            setLoading(false);
            dispatch(setInvoiceResId(response.applicationNumber));
            history.push(urls.getCreatedInvoiceResultPath(user.id));
        } else {
            setLoading(false);
        }
      } catch (e) {
          setLoading(false);
      }

    };
  
    const handleCancel = () => {
      setIsModalOK(false);
    };

    const getInvoice = async () => {
        try {
            setLoading(true);
            const {
                invoiceDateBegin,
                invoiceDateEnd,
                invoiceTermBegin,
                invoiceTermEnd,
                minInvoiceAmount,
                maxInvoiceAmount,
                invoiceNumber,
              } = filters;     
              const filterStr = `${invoiceDateBegin ? `invoiceDateBegin=${invoiceDateBegin}&` : ''}
                ${invoiceDateEnd ? `invoiceDateEnd=${invoiceDateEnd}&` : ''}
                ${invoiceTermBegin ? `invoiceTermBegin=${invoiceTermBegin}&` : ''}
                ${invoiceTermEnd ? `invoiceTermEnd=${invoiceTermEnd}&`: ''}
                ${minInvoiceAmount ? `minInvoiceAmount=${minInvoiceAmount}&` : ''}
                ${maxInvoiceAmount ? `maxInvoiceAmount=${maxInvoiceAmount}&` : ''}
                ${invoiceNumber ? `invoiceNumber=${invoiceNumber}&` : ''}
                `;
            const response = await fetchInvoices(user.taxId, isLoggedIn, filterStr.trim().replaceAll(/\s/g,''), pSize, pNumber); 
            if (response) {
                setLoading(false);
                setInvoicesData(response.invoices.data === null ? [] : response.invoices.data.sort((a, b) => b.id - a.id))
                dispatch(setInvoices(response.invoices.data.sort((a, b) => b.id - a.id)));
                setTotalDataCount(response.invoices.totalDataCount);
            } else {
                setLoading(false);
            }
        } catch (e) {
            setLoading(false);
        }
    };

    const handleUploadInvoice = async (financialId, financialName,supplierAverageCreditDay, financialTaxId) => {
   
        const invoiceData = selectInvoice.map((item) =>
        (
            {
                id : item.id,
                date :  moment.tz(item.invoiceDate, 'Europe/Istanbul').format(),
                fileName : item.fileName,
                number : item.invoiceNumber,
                total : item.invoiceTotal,
                currency :item.currency,
                term : moment.tz(item.invoiceTerm, 'Europe/Istanbul').format(), 
                isApproved : item.isApproved,
                createdDate : moment.tz(item.createdDate, 'Europe/Istanbul').format(),
                hashCode : item.hashCode,
                invoiceType: item.invoiceType,
            }
        )
     ) 

        if(financialTaxId === '2651554884'){
            setIsModalEntry(true);
            setInvoiceDataArray(invoiceData)
            setSupplierAverageCreditDayArray(supplierAverageCreditDay || 0);
            setFinancialInstitutionsArray(
              {
                id: financialId,
                name: financialName,
              },
            )
        }else{

        try {
            setLoading(true);
            const response = await uploadInvoices(
                {
                    supplierId: user.id,
                    buyer : {
                        id: invoices[0].buyerId,
                        title :  selectbuyer.buyerTitle,
                        taxId : selectbuyer.buyerTaxNumber,
                    },
                    averageMaturity: supplierAverageCreditDay || 0,
                    invoices : invoiceData,
                    financialInstitutions: [
                        {
                            id: financialId,
                            name: financialName,
                        },
                    ],
                },
                isLoggedIn
            );
           
            if (response) {
                setLoading(false);
                dispatch(setInvoiceResId(response.applicationNumber));
                history.push(urls.getCreatedInvoiceResultPath(user.id));
            } else {
                setLoading(false);
            }
        } catch (e) {
            setLoading(false);
        }
      } 
    };


    const getUserData = async () => {
        const response = await fetchUser(isLoggedIn);
        if (response) {
            dispatch(setUser(response));
        }
    };

    const handleDateSearch = (confirm) => {
        getInvoice()
        confirm();
      };
    
      const handleReset = (clearFilters) => {
         clearFilters();
        form.resetFields();
        setFilteredInfo(null)
        setFilters({
            ...filters, 
            invoiceDateBegin: '',
            invoiceDateEnd: '',
            invoiceTermBegin: '',
            invoiceTermEnd: '',
            });
      };

      const handleInvoiceNumberReset = () => {
        form.resetFields();
        setFilters({
            ...filters, 
            invoiceNumber: ''
            });
      };

      const handleTotalReset = () => {
        form.resetFields();
        setFilters({
            ...filters, 
            minInvoiceAmount:'',
            maxInvoiceAmount:'',
            });
      };


      const checkDate = (checkdate, date1, date2) => (
         moment(moment(checkdate).format('YYYY-MM-DD')).isBetween(moment(moment(date1).format('YYYY-MM-DD')), moment(moment(date2).format('YYYY-MM-DD')))
      )

      const getColumnInvoiceNumberProps = () => ({
        filterDropdown: ({ setSelectedKeys,selectedKeys,confirm}) => (
            <div style={{ padding: 10, display: 'grid', justifyItems: 'center' }}>
              <Text style={{ fontWeight: 'bold', marginBottom: 10 }}> Fatura No Giriniz</Text>
              <div>
                <Form form={form}>
                    <Form.Item
                        value={selectedKeys[0]}
                        style={{marginBottom:'0'}}
                        name='invoiceNumber'
                        onBlur={(e) => {
                        setSelectedKeys(e.length ? [e] : [])
                        setFilters({
                            ...filters, 
                            invoiceDateBegin:filters.invoiceDateBegin,
                            invoiceDateEnd:filters.invoiceDateEnd,
                            invoiceTermBegin:filters.invoiceTermBegin,
                            invoiceTermEnd: filters.invoiceTermEnd,
                            minInvoiceAmount:filters.minInvoiceAmount,
                            maxInvoiceAmount:filters.maxInvoiceAmount,
                            invoiceNumber: e.target.value || filters.invoiceNumber
                        })
                    }}
                    >
                    <Input allowClear />
                    </Form.Item>
                </Form>
               </div>
              <div style={{ marginTop: 15 }}>
                <Button
                  style={{ width: 90 }}
                  onClick={() => handleInvoiceNumberReset()}
                  size="small"
                >
                  Sıfırla
                </Button>
                <Button
                  type="primary"
                  role="search"
                  onClick={() => {
                    handleDateSearch( confirm)
                  }}
                  style={{ width: 90, height: 24, borderRadius: 0, marginLeft: 8 }}
                  icon={<SearchOutlined />}
                  size="small"
                >
                  Ara
                </Button>
              </div>
            </div>
          ),
      } )

    
      const getColumnTotalProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys,selectedKeys,confirm}) => (
            <div style={{ padding: 10, display: 'grid', justifyItems: 'center' }}>
              <Text style={{ fontWeight: 'bold', marginBottom: 10 }}>Tutar Aralığı Giriniz</Text>
              <div >
                <Form form={form} style={{display:'flex', gap:'10px', width:'300px'}}>
                    <Form.Item
                        value={selectedKeys[0]}
                        name='minInvoiceAmount'
                        style={{marginBottom:'0'}}
                        onChange={(e) => {
                            setSelectedKeys(e.length ? [e] : [])
                            setFilters({
                               ...filters, 
                               invoiceDateBegin:filters.invoiceDateBegin,
                               invoiceDateEnd:filters.invoiceDateEnd,
                               invoiceTermBegin:filters.invoiceTermBegin,
                               invoiceTermEnd: filters.invoiceTermEnd,
                               minInvoiceAmount:e.target.value || filters.minInvoiceAmount,
                               maxInvoiceAmount:filters.maxInvoiceAmount,
                               invoiceNumber: filters.invoiceNumber
                             })
                           }}
                    >
                    <Input allowClear  placeholder='min tutar' />
                    </Form.Item>
                    <Form.Item
                       value={selectedKeys[0]}
                       style={{marginBottom:'0'}}
                       name='maxInvoiceAmount'
                       onChange={(e) => {
                           setSelectedKeys(e.length ? [e] : [])
                           setFilters({
                             ...filters, 
                             invoiceDateBegin:filters.invoiceDateBegin,
                             invoiceDateEnd:filters.invoiceDateEnd,
                             invoiceTermBegin:filters.invoiceTermBegin,
                             invoiceTermEnd: filters.invoiceTermEnd,
                             minInvoiceAmount:filters.minInvoiceAmount,
                             maxInvoiceAmount:e.target.value || filters.maxInvoiceAmount,
                             invoiceNumber: filters.invoiceNumber
                           })
                         }}
                    >
                    <Input allowClear  placeholder='max tutar' />
                    </Form.Item>
                </Form>
            
              </div>
              <div style={{ marginTop: 15 }}>
                <Button
                  style={{ width: 90 }}
                  onClick={() => handleTotalReset()}
                  size="small"
                >
                  Sıfırla
                </Button>
                <Button
                  type="primary"
                  role="search"
                  onClick={() => {
                    handleDateSearch( confirm)
                  }}
                  style={{ width: 90, height: 24, borderRadius: 0, marginLeft: 8 }}
                  icon={<SearchOutlined />}
                  size="small"
                >
                  Ara
                </Button>
              </div>
            </div>
          ),
          filteredValue: (filteredInfo && filteredInfo[dataIndex]) || null,
      });

    const getColumnTimeProps = (dataIndex) => ({
        filterDropdown: ({setSelectedKeys, selectedKeys, confirm,clearFilters}) => (
          <div style={{ padding: 10, display: 'grid', justifyItems: 'center' }}>
            <Text style={{ fontWeight: 'bold', marginBottom: 10 }}>Tarih Aralığı Giriniz</Text>
            <DatePicker.RangePicker
              onChange={(e) => {
                setSelectedKeys(e.length ? [e] : [])
                setFilters({
                  ...filters,
                  invoiceDateBegin: (dataIndex === 'invoiceDate' && e.length > 0 && new Date(e[0]).toISOString()) || filters.invoiceDateBegin,
                  invoiceDateEnd: (dataIndex === 'invoiceDate' && e.length > 0 && new Date(e[1]).toISOString()) || filters.invoiceDateEnd,
                  invoiceTermBegin: (dataIndex === 'invoiceTerm' && e.length > 0 && new Date(e[0]).toISOString()) || filters.invoiceTermBegin,
                  invoiceTermEnd: (dataIndex === 'invoiceTerm' && e.length > 0 && new Date(e[1]).toISOString()) || filters.invoiceTermEnd,
                  minInvoiceAmount:filters.minInvoiceAmount,
                  maxInvoiceAmount:filters.maxInvoiceAmount,
                  invoiceNumber: filters.invoiceNumber
                })
              }}
              allowClear={false}
              style={{ width: 190 }}
              value={selectedKeys[0]}
              format="YYYY-MM-DD"
            />
            <div style={{ marginTop: 15 }}>
              <Button
                style={{ width: 90 }}
                onClick={() => handleReset(clearFilters)}
                size="small"
              >
                Sıfırla
              </Button>
              <Button
                type="primary"
                role="search"
                onClick={() => {
                  handleDateSearch(confirm)
                }}
                style={{ width: 90, height: 24, borderRadius: 0, marginLeft: 8 }}
                icon={<SearchOutlined />}
                size="small"
              >
                Ara
              </Button>
            </div>
          </div>
        ),
        filterIcon: (filtered) => (
          <CalendarOutlined type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
        ),
        // filteredValue: (filteredInfo && filteredInfo[dataIndex]) || null,
        onFilter: (value, record) => record[dataIndex] ? checkDate(record[dataIndex],value[0], value[1]) : '',
        render: (text) => moment(text).format('DD-MM-YYYY')
      });
   
    const columns = [
        {
            title: 'Fatura No',
            dataIndex: 'invoiceNumber',
            key: 'invoiceNumber',
            fixed: 'left',
            ...getColumnInvoiceNumberProps('invoiceNumber'),
        },
        {
            title: 'Fatura Tarihi',
            dataIndex: 'invoiceDate',
            key: 'invoiceDate',
            render: (value) => moment(value).format('DD-MM-YYYY'),
            ...getColumnTimeProps('invoiceDate'),
        },
        {
            title: 'Borçlu VKN',
            dataIndex: 'buyerTaxNumber',
            key: 'buyerTaxNumber',
        },
        {
            title: 'Borçlu Ünvanı',
            dataIndex: 'buyerTitle',
            key: 'buyerTitle',
        },
        {
            title: 'Fatura Tutarı',
            dataIndex: 'invoiceTotal',
            key: 'invoiceTotal',
            render: convertFloatDotSeperated,
            ...getColumnTotalProps('invoiceTotal'),
        },
        {
            title: 'Fatura Vadesi',
            dataIndex: 'invoiceTerm',
            key: 'invoiceTerm',
            render: (value) => moment(value).format('DD-MM-YYYY'),
            ...getColumnTimeProps('invoiceTerm'),
        },
    ];

   
    useEffect(() => {
        isLoggedIn && getUserData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoggedIn]);

    useEffect(() => {     
        if (user && user.taxId) {
            getInvoice();
            // getFinancialInstitution();
            getRisks();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user,pNumber, pSize,
        filters.maxInvoiceAmount ==='' && filters.minInvoiceAmount === '', 
        filters.invoiceTermBegin ==='' && filters.invoiceTermEnd === '',
        filters.invoiceDateBegin ==='' && filters.invoiceDateEnd === '',
        filters.invoiceNumber === ''
    ]);

    const handleShowSize = (current, size) => {
        setpNumber(current);
        setPsize(size);
      };
   
    
    return (
        <>
        <Spin tip="Hesaplanıyor.." spinning={pageLoading}>
            {!loading && invoices && invoices.length === 0 ? (
                <Row>
                    <Row style={{ width: '68%', margin: '40px auto 0px' }}>
                        <Col style={{ margin: '0px auto', textAlign: 'center', display: 'grid' }}>
                            <Text style={{ fontSize: 18, fontWeight: 800, marginBottom: 20 }}>
                                Yüklenen faturalarınız bulunmamaktadır.
                            </Text>
                            <Text>
                                Bu fatura türünü görmeniz için alıcının fatura yüklemesi gerekmektedir.
                            </Text>
                            <Text>
                                Alıcı bu faturaları yükledikten sonra sistemimizde yüklenen faturalarınızı
                                görebilirsiniz.
                            </Text>
                        </Col>
                    </Row>
                    <HelpBox>
                        <Col className="info_box">
                            <img src={AliciTedarikci} alt="alici_tedarikci" />
                            <Text>
                                Alıcı, Tedarikçi faturalarını sisteme yüklediğinde, Tedarikçi'ye otomatik
                                bildirim iletilir.
                            </Text>
                        </Col>
                        <Col className="info_box">
                            <img src={Tedarikciler} alt="Tedarikciler" />
                            <Text>
                                Tedarikçi, bu faturalardan uygun bulduklarını seçerek erken ödeme almak için
                                teklif ister.
                            </Text>
                        </Col>
                     
                        <Col className="info_box">
                            <img src={Finans} alt="finans" />
                            <Text>Finans kurumları, işletmelerin faturalarına teklif verir.</Text>
                        </Col>
                        <Col className="info_box">
                            <img src={FaturaVadesi} alt="FaturaVadesi" />
                            <Text>
                                Fatura vadesi geldiğinde, Alıcı fatura ödemesini ilgili finans kurumuna
                                gerçekleştirir.
                            </Text>
                        </Col>
                    </HelpBox>
                </Row>
            ) : (
                <>
                     <Row style={{ marginTop:'20px', marginBottom:'20px'}}>
                        <Col xl={24}>
                        <Text className='page-title'>Yüklenen Faturalarım</Text>
                        </Col>
                    </Row>
                    <Row style={{marginBottom:'30px'}}>
                        <Col span={24} style={{ margin: '30px auto 30px' }}>
                            <Table
                                className="invoiceTable"
                                pagination={{
                                    pageSizeOptions: ['10', '20', '50', '100', '250', '500'],
                                    position: ['topRight', 'none'],
                                    showLessItems: true,                          
                                    showSizeChanger: true,
                                    current: pNumber,
                                    pageSize: pSize,
                                    total:totalDataCount,                                                       
                                    onChange: handleShowSize, 
                                }}
                                rowSelection={{
                                    type: 'checkbox',
                                    ...rowSelection,
                                }}
                                rowKey="id"
                                dataSource={invoicesData}
                                columns={columns}
                                loading={loading}
                                cursorPointer
                                scroll={{
                                    x: 1200,
                                  }}
                            />
                        </Col>
                    </Row>
                   <Row className='financial-list-row'>
                    {   !active && !boxcActive ? 
                        <Col style={{textAlign:'center', width:'100%'}}>
                         <h3>İskonto ettirmek istediğiniz faturaları seçiniz.</h3>
                        </Col>
                        :
                        financialList.map(item => (
                            <InvoicesDiscountSummary key={item.id}
                            onChange = {() => handleUploadInvoice(item.financialId, item.financialName, item.supplierAverageCreditDay, item.financialTaxId)}
                            invoiceCalculate={{
                                financialName: item.financialName,
                                financialId: item.financialId,
                                invoiceCount: (selectInvoice && selectInvoice.length) || 0,
                                invoiceTotal: item.total,
                                supplierAverageCreditDay: item.supplierAverageCreditDay || 0,
                                price: item.price,
                                supplierLimit : item.supplierLimit,
                                supplierRisk : item.supplierRisk,
                            }}
                        />
                        ))
                    }
                
                   </Row>
                </>
            )}
        </Spin>
        <Modal
            open={isModalEntry}
            className='modal-iban'
            footer={false}
            closable={false}
            >
            <h3>Başvuru Ödemesi Yapılacak IBAN</h3>
            <p>Lütfen başvurunuzun D Yatırım Bankası tarafından onaylanması durumunda, ödemenizin yapılmasını istediğiniz IBAN numarasını giriniz.</p>
            <Form
              form={formIban}
              name="iban_form"
              layout="vertical"
              onFinish={onFinishConfirmation}
            >
              <Form.Item
                name="iban"
                rules={[
                  {
                    required: true,
                    message: 'Lütfen IBAN numaranızı giriniz!',
                  },
                  {
                    pattern: /^TR\d{2} \d{4} \d{4} \d{4} \d{4} \d{4} \d{0,2}$/,
                    message: 'Geçerli bir IBAN numarası giriniz!',
                  },
                ]}
              >
                <InputMask
                  mask="TR99 9999 9999 9999 9999 9999 99"
                >
                  {() => <Input placeholder="TR00 0000 0000 0000 0000 0000 00" />}
                </InputMask>
              </Form.Item>
              <div style={{display:'flex'}} className='iban-modal-btn'>
                <Form.Item className='iban-modal-btn-xx'>
                  <Button className='close-btn'  onClick={ibanModalClose} style={{color:'#000 !important'}} >
                    İptal
                  </Button>
                </Form.Item>
                <Form.Item  className='iban-modal-btn-xx'>
                <Button className='submit-btn'  type="primary" htmlType="submit">
                  Onayla
                </Button>
              </Form.Item>
              </div>
              
          </Form>
        </Modal>
        <Modal
            open={isModalOk}
            className='modal-iban modal-iban-confirmation'
            closable={false}
            onOk={handleOk} 
            onCancel={handleCancel}
            >
            <p> Girmiş olduğunuz IBAN numarası <br/> <strong>{ibanNo.iban} </strong> olarak D Yatırım Bankası'na iletilecektir. Onaylıyor musunuz?</p>
           
        </Modal>
        </>
    );
};

export default SupplierFinancingList;
