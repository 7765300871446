import React from 'react';
import { Col } from 'antd';
import { InvoiceText, InvoiceRow, InvoiceCol, FooterBox, InvoiceValue, InvoiceButton } from './styles';
import { convertFloatDotSeperated } from '../../../../utils';


const InvoicesDiscountSummary = ({ invoiceCalculate, onChange }) => (
    <FooterBox style={{ margin: '5px auto'}} >
        <Col  background="primaryFaded" margin={false} style={{ width:'100%' }} >
            <InvoiceRow className='invoiceRow'>
                <InvoiceCol style={{minWidth:'220px'}}>
                    <InvoiceText type="mini-title" className='border-box'>
                        <h3>{invoiceCalculate?.financialName}</h3>
                        <h4>Risk:  {convertFloatDotSeperated(invoiceCalculate?.supplierRisk)} TL</h4>
                        <h4>Limit: {convertFloatDotSeperated(invoiceCalculate?.supplierLimit)} TL</h4>                      
                    </InvoiceText>
                </InvoiceCol>
                <InvoiceCol style={{ minWidth:'120px'}} className='num-invoice-box'>
                    <InvoiceText type="mini-title">
                        <b>Fatura Adedi:</b>
                    </InvoiceText>
                    <InvoiceValue style={{ textAlign: 'left' }} type="mini-title">
                        {invoiceCalculate?.invoiceCount}
                    </InvoiceValue>
                </InvoiceCol>
                <InvoiceCol style={{ minWidth:'130px'}}>
                    <InvoiceText type="mini-title">
                        <b>Toplam Tutar:</b>
                    </InvoiceText>
                    <InvoiceValue style={{ textAlign: 'left' }} type="mini-title">
                        {convertFloatDotSeperated(invoiceCalculate?.invoiceTotal)} TL
                    </InvoiceValue>
                </InvoiceCol>
                <InvoiceCol style={{ minWidth:'130px'}} className='maturity-rate-box'>
                    <InvoiceText type="mini-title">
                        <b>Ortalama Vade:</b>
                    </InvoiceText>
                    <InvoiceValue style={{ textAlign: 'left' }} type="mini-title">
                        {invoiceCalculate?.supplierAverageCreditDay} Gün
                    </InvoiceValue>
                </InvoiceCol>
              
                <InvoiceCol style={{ minWidth:'160px'}}>
                    <InvoiceText type="mini-title">
                        <b>Elinize Geçecek Tutar: </b>
                    </InvoiceText>
                    <InvoiceValue style={{ textAlign: 'left' }} type="mini-title">
                         {convertFloatDotSeperated((invoiceCalculate?.price))} 
                    </InvoiceValue>
                </InvoiceCol>
                <InvoiceCol className='last-limit' style={{ minWidth:'120px'}}>
                    <InvoiceText type="mini-title">
                        <b>Kalacak Limit: </b>
                    </InvoiceText>
                    <InvoiceValue style={{ textAlign: 'left' }} type="mini-title">
                        { convertFloatDotSeperated(invoiceCalculate?.supplierLimit - (invoiceCalculate?.supplierRisk + invoiceCalculate?.invoiceTotal ))} TL                        
                    </InvoiceValue>
                </InvoiceCol>
                <InvoiceCol style={{paddingLeft:'0', marginRight:'4px'}}>
                    <InvoiceButton
                        key="invoice-discount-button"
                        type="primary"
                        className="apply-box"
                        onClick={onChange}
                        loading={false}>
                       {invoiceCalculate?.financialName}'a Başvur
                    </InvoiceButton>
                </InvoiceCol>
            </InvoiceRow>
        </Col>
    </FooterBox>
);

export default InvoicesDiscountSummary;
