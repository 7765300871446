import { Row } from 'antd';
import styled from 'styled-components';
import Text from '../../../../components/Text';
import Button from '../../../../components/Button';


export const FooterBox = styled(Row)`

    width: 100%;
    border-radius: 8px;
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.25);
    background-color: #fff;
    max-width:1200px                                                                                                                    
   
    h3{
        color:#0d0f7b;
        text-decoration: underline;
        font-weight: 800;
        white-space: nowrap
    }
    h4{
        color:#0d0f7b;
        white-space: nowrap
    }
    b{
        font-size:13px;
        font-weight: 500;
    }
    .border-box{
        padding: 7px 11.2px 5.3px 10px;
        border-radius: 8px;
        box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.25);
        background-color: #fafbff;
        margin-bottom: 0;
    }
    .invoiceRow div{
        border-right:1px solid #0d0f7b;
        width: calc(100%/7);   
        
    }
   .invoiceRow div:first-child{
        border-right:none;    
    }
    .invoiceRow div:nth-last-child(1){
        border-right:none;    
    }
    .invoiceRow div:nth-last-child(2){
        border-right:none;    
    }
    .last-limit b{
        color:#e34230
    }
    .last-limit h4{
        color:#e34230;
        white-space: nowrap
    }
    @media only screen and (max-width: 1680px) {
        .interest-rate-box{
            max-width: 106px;
        }
        .maturity-rate-box{
            max-width: 130px;
        }
        .num-invoice-box{
            max-width: 120px;
        }
        .apply-box{
            padding:0
        }
      }
      
    @media only screen and (max-width: 1400px) {
        min-width: 1360px;
      }
      
`;



export const InvoiceText = styled(Text)`
    line-height: 1.05;
    font-weight: 300;
    color: ${({ theme }) => theme.main.primaryColor};
  
`;

export const InvoiceValue = styled(Text)`
    line-height: 1;
    font-weight: 600;
    color: ${({ theme }) => theme.main.primaryColor};
`;

export const InvoiceRow = styled.div`
    display: flex;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    @media only screen and (max-width: 1680px) {
        justify-content:space-between;
        gap: 0;
      }
`;

export const InvoiceCol = styled.div`   
padding:8px;
    @media only screen and (max-width: 1680px) {
        padding:0;
        padding-top:5px;
        padding-bottom:5px;
        padding-left: 5px;
        :first-child{
            padding-left:8px;   
        }
    }
`;

export const InvoiceButton = styled(Button)`
    padding-left: 27px;
    paddind-right: 27px;
    background-color: ${({ theme }) => theme.main.primaryColor};
    border-color: ${({ theme }) => theme.main.primaryColor};
    min-width : 168px;
    font-weight: 600;
   
`;
