import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Col, Row, Table } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import Text from '../../components/Text';
import { getPaidInvoice } from '../../apiServices/fundApi';
import { setPaidInvoices } from '../../store/reducers/fundSlice';
import { convertFloatDotSeperated } from '../../utils';


function PaidInvoices() {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const { user } = useSelector((state) => state.user);
    const { paidInvoices } = useSelector((state) => state.fund);
    const [pSize, setPsize] = useState(10);
    const [pNumber, setpNumber] = useState(1);
    const [totalDataCount, setTotalDataCount] = useState(1);

    const getPaidInvoices = async () => {
        try {
            setLoading(true);
            const response = await getPaidInvoice(user.id, pNumber, pSize);   
             
            if (response) {
                setLoading(false);
                dispatch(setPaidInvoices(response.invoices.data));
                setTotalDataCount(response.invoices.totalDataCount)
            } else {
                setLoading(false);
            }
        } catch (e) {
            setLoading(false);
        }
    };

    const handleShowSize = (current, size) => {
        setpNumber(current);
        setPsize(size);
      };

    useEffect(() => {
        user && user.id && getPaidInvoices();        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);
   
    const tableCols = [
        {
            title: 'Alıcı Ünvanı',
            dataIndex: 'buyerTitle',
            key: 'buyerTitle',
            width: 180,
        },
        {
            title: 'Fatura No',
            dataIndex: 'invoiceNumber',
            key: 'invoiceNumber',
        },
        {
            title: 'Fatura Tarihi',
            dataIndex: 'invoiceDate',
            key: 'invoiceDate',
            render: (date) => moment(date).format('DD-MM-YYYY'),
        },
        {
            title: 'Fatura Tutarı',
            dataIndex: 'invoiceTotal',
            key: 'invoiceTotal',
            render: convertFloatDotSeperated,
        },
        {
            title: 'Fatura Vadesi',
            dataIndex: 'invoiceTerm',
            key: 'invoiceTerm',
            render: (date) => moment(date).format('DD-MM-YYYY'),
        },
        {
            title: 'Alıcı VKN',
            dataIndex: 'buyerTaxNumber',
            key: 'buyerTaxNumber',
        },
        {
            title: 'Temlik Ettiğim Kurum',
            dataIndex: 'financialInstitutionName',
            key: 'financialInstitutionName',
        },
    ];

    return (
        <>
              <Row style={{ marginTop:'20px', marginBottom:'20px'}}>
                <Col xl={24}>
                <Text className='page-title'>Alıcı Ödemesi Gerçekleşen Faturalarım</Text>
                </Col>
            </Row>
        
            <Row>
                <Col span={24} >
                    <Table
                        rowKey="invoiceNumber"
                        loading={loading}
                        dataSource={paidInvoices}
                        columns={tableCols}
                        cursorPointer
                        pagination={{
                            position: ['topRight', 'none'],
                            showLessItems: true,                          
                            showSizeChanger: true,
                            current: pNumber,
                            pageSize: pSize,
                            total:totalDataCount,                                                       
                            onChange: handleShowSize
                        }}
                        size="middle"
                    />
                </Col>
            </Row>
        </>
    );
}

export default PaidInvoices;
